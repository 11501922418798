<template>
    <div class="grid">
        <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
        <div class="col-12">
            <div class="card">
                <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" />
                <h5>{{show_name}} Client</h5>
                <hr>
                <div class="p-fluid formgrid grid">

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="client_name" type="text" v-model="product.client_name"
                                :class="{ 'p-invalid': submitted && !product.client_name }" />
                            <label for="client_name">Client Name</label>
                        </span>
                    </div>


                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown   id="status"  
                                    v-model="status_dropdownItem" 
                                    :options="status_dropdownItems"
                                    optionLabel="name" 
                                   
                                    :class="{ 'p-invalid': submitted && !status_dropdownItem }">
                        </Dropdown>
                        <label for="status">Status</label>
                    </span>
                    </div>
                   
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown   id="User"  filter
                                    v-model="user_dropdownItem" 
                                    :options="user_dropdownItems" 
                                    optionLabel="name"
                                    :class="{ 'p-invalid': submitted && !user_dropdownItem }">
                        </Dropdown>
                        <label for="User">User</label>
                    </span>
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText  type="number" 
                                        id="mobile_number" 
                                        v-model="product.mobile_number"
                                        :class="{ 'p-invalid': submitted && !product.mobile_number }" />
                            <label for="mobile_number">Mobile Number</label>
                        </span>
                    </div>

                    
                    <!-- <div class="field col-12 md:col-6" style="margin-top: 1%;"></div> -->
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                        <Dropdown   id="status" filter
                                    v-model="client_type_item" 
                                    :options="client_type_items"
                                    optionLabel="name" 
                                   
                                    :class="{ 'p-invalid': submitted && !client_type_items }">
                        </Dropdown>
                        <label for="status">Client Type</label>
                    </span>
                    </div>
                    
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText  type="email" 
                                        id="email_id" 
                                        v-model="product.email_id"
                                        :class="{ 'p-invalid': submitted && !product.email_id }" />
                            <label for="email_id">Email ID</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <!-- <span class="p-float-label"> -->
                            <label for="Location">Location</label>
                        <Dropdown 
                                    @change="get_location"
                                    v-model="location_search" 
                                    :options="location_dropdown" 
                                    optionLabel="name" 
                                    filter 
                                    @filter="dropdown_list"
                                    placeholder="Location" 
                                    >
                            <template #value="slotProps">
                            <div class="country-item country-item-value" v-if="slotProps.value">
                                <div>{{slotProps.value.name}}</div>
                            </div>
                                <span v-else>
                                {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                            <span>{{slotProps.option.name}}</span>
                            </template>
                            
                        </Dropdown>
                        
                    <!-- </span> -->
                    </div>

                    <div class="field col-12 md:col-6" style="margin-top: 1.5%;">
                    <br>
                 <span class="p-float-label">
               <Dropdown
               id="state" filter 
              
               v-model="role_group_search"  
               :options="role_group_dropdown" 
               optionLabel="name" 
               :optionValue="value"></Dropdown>
               <label for="client">Role Group</label>
             </span>
                    
                    </div>
                    

                    
                    


                    

                    <!-- <div class="field col-12 md:col-4" style="margin-top: 1%;">
                           <span class="p-float-label">
                        <Dropdown id="state" v-model="state_dropdownItem" :options="state_dropdownItems" optionLabel="name"
                             :class="{ 'p-invalid': submitted && !state_dropdownItem }">
                        </Dropdown>
                        <label for="state">State</label>
                        </span>
                        
                    </div> -->
                    
                    <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label">

                            
                            <InputText id="city" type="text" v-model="product.city"
                                :class="{ 'p-invalid': submitted && !product.city }" />
                            <label for="city">City</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <InputText id="zip" type="text" v-model="product.pinvalue"
                                :class="{ 'p-invalid': submitted && !product.pinvalue }" />
                            <label for="zip">PinCode</label>
                        </span>
                    </div>
                    <div class="field col-12 md:col-4" style="margin-top: 1%;">
                           <span class="p-float-label">
                        <Dropdown id="state" v-model="state_dropdownItem" :options="state_dropdownItems" optionLabel="name" filter
                             :class="{ 'p-invalid': submitted && !state_dropdownItem }">
                        </Dropdown>
                        <label for="state">State</label>
                        </span>
                        
                    </div>
                    <div class="field col-12" style="margin-top: 1%;">
                        <span class="p-float-label">

                            <Textarea id="description" rows="4" v-model="product.description"
                                :class="{ 'p-invalid': submitted && !product.description }" />
                            <label for="description">description</label>
                        </span>
                    </div>
                   
                    </div>
                    <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-4" style="margin-top: 1%;"></div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <Button v-if="product._id" label="Update" class="p-button-success mr-2" @click="Add_user"   v-tooltip.bottom="'Update'"/>
                        <Button v-else label="Add" class="p-button-success mr-2" @click="Add_user"   v-tooltip.bottom="'Add'"/>
                    </div>
                    <div class="field col-12 md:col-2" style="margin-top: 1%;">
                        <Button label="Reset" class="p-button-danger mr-2"   v-tooltip.bottom="'Reset'" @click="reset_user" />
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import BreadcrumbService from "../service/BreadcrumbService";
import JsonExcel from "vue-json-excel";
import apis from "@/apis";
export default {
    data() {
        return {
            role_group_search:null,
            role_group_dropdown:[],


            isLoading: false,
        isLoadingModel: false,
        loading: false,
        fullPage: true,

            location_dropdown: [],
            location_search:'',
            place_details:'',


            client_type_items:[],
            client_type_item:'',


            id: "",
            list: [],
            client_role_group: '',
            status_dropdownItems: [
                { name: "Active", value: "Active" },
                { name: "InActive", value: "InActive" },
                { name: "Deleted", value: "Deleted" },
            ],
            status_dropdownItem: null,
            show_name:"Add",
            


            state_dropdownItems: [{ name: 'Andhra Pradesh', value: 'Andhra Pradesh' }, { name: 'Arunachal Pradesh', value: 'Arunachal Pradesh' }, { name: 'Assam', value: 'Assam' }, { name: 'Bihar', value: 'Bihar' }, { name: 'Chhattisgarh', value: 'Chhattisgarh' }, { name: 'Goa', value: 'Goa' }, { name: 'Gujarat', value: 'Gujarat' }, { name: 'Haryana', value: 'Haryana' }, { name: 'Himachal Pradesh', value: 'Himachal Pradesh' }, { name: 'Jharkhand', value: 'Jharkhand' }, { name: 'Karnataka', value: 'Karnataka' }, { name: 'Kerala', value: 'Kerala' }, { name: 'Madhya Pradesh', value: 'Madhya Pradesh' }, { name: 'Maharashtra', value: 'Maharashtra' }, { name: 'Manipur', value: 'Manipur' }, { name: 'Meghalaya', value: 'Meghalaya' }, { name: 'Mizoram', value: 'Mizoram' }, { name: 'Nagaland', value: 'Nagaland' }, { name: 'Odisha', value: 'Odisha' }, { name: 'Punjab', value: 'Punjab' }, { name: 'Rajasthan', value: 'Rajasthan' }, { name: 'Sikkim', value: 'Sikkim' }, { name: 'Tamil Nadu', value: 'Tamil Nadu' }, { name: 'Telangana', value: 'Telangana' }, { name: 'Tripura', value: 'Tripura' }, { name: 'Uttar Pradesh', value: 'Uttar Pradesh' }, { name: 'Uttarakhand', value: 'Uttarakhand' }, { name: 'West Bengal', value: 'West Bengal' }],
            state_dropdownItem: null,

            

            user_dropdownItems: [],
            user_dropdownItem: null,


            product: {},
            submitted: false,
            BreadcrumbService : null,
            breadcrumbHome: {},
            breadcrumbItems: [],
        }

    },
    productService: null,
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
  },
    methods: {
        get_location()
      { 
        this.place_details='';
        var data = { PlaceId:this.location_search.value};
        var promise = apis.geocodelocationdetails(data);
        promise.then((response) => {
          if (response.data.result) 
          {
            this.place_details=response.data.result[0];
            this.state_dropdownItem.name=response.data.result[0].Region?response.data.result[0].Region:'';
            this.state_dropdownItem.value=response.data.result[0].Region?response.data.result[0].Region:'';
            this.product.pinvalue=response.data.result[0].PostalCode?response.data.result[0].PostalCode:'';
            this.product.city=response.data.result[0].Municipality?response.data.result[0].Municipality:'';
          }
        });
      },
       dropdown_list(search)
      { 
       var data = { search:search.value};
        var promise = apis.geocodelats(data);
        promise.then((response) => {
          this.location_dropdown=[];
          var temp=response.data.result;
          for (let a = 0; a < temp.length; a++) {
            this.location_dropdown.push( { name: temp[a].Text, value: temp[a].PlaceId });
            
          }
        });
      },

        Add_user() {
            this.submitted = true;
            this.product.location=this.place_details;
            if (!this.product.client_name) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Client Name",
                    life: 3000,
                });
                return false;
            }
            if (this.status_dropdownItem==null || this.status_dropdownItem.value=='' || this.status_dropdownItem.value==undefined ||  this.status_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select Status",
                    life: 3000,
                });
                return false;
            }
            if (this.user_dropdownItem==null || this.user_dropdownItem.value=='' || this.user_dropdownItem.value==undefined ||  this.user_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select User",
                    life: 3000,
                });
                return false;
            }
            const phoneRegex = /^[0-9]{10}$/;
            if (!this.product.mobile_number || (this.product.mobile_number && !phoneRegex.test(this.product.mobile_number))) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter valid Mobile No",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.location) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Location",
                    life: 3000,
                });
                return false;
            }
            if (this.state_dropdownItem==null || this.state_dropdownItem.value=='' || this.state_dropdownItem.value==undefined ||  this.state_dropdownItem.value==[]) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Select State",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.city) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter city",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.pinvalue) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Pin code",
                    life: 3000,
                });
                return false;
            }
            if (!this.product.description) 
            {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter Description",
                    life: 3000,
                });
                return false;
            }
            var emailreg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            if(this.product.email_id)
            {
                if(emailreg.test(this.product.email_id)==false)
                {
                this.$toast.add({
                    severity: "error",
                    summary: "error",
                    detail: "Please Enter valid Email ID",
                    life: 3000,
                });
                  return false;
                }
            }


           
            if(this.product._id)
            {
                var data1 = {
                "_id":this.product._id,
                "client_name": this.product.client_name,
                "description":this.product.description,
                "city": this.product.city,
                "state": this.state_dropdownItem.value,
                "pincode": this.product.pinvalue,
                "email_id": this.product.email_id,
                "location":this.product.location,
                "status": this.status_dropdownItem.value,
                "user_id": this.user_dropdownItem.value,
                "created_at": "",
                "created_by": this.id,
                "mobile_number": this.product.mobile_number,
                //"client_role_group": localStorage.getItem('user_key'),
                "client_role_group":this.role_group_search?this.role_group_search.value:'',
                "client_type":this.client_type_item.value,
                lang:this.$i18n.locale
                };


               
                this.isLoadingModel = true;
                var promise = apis.editclient(data1);
                promise
                    .then((responseapi) => {
                       // this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});
                        this.$toast.add({
                      severity: "success", // success, info, warn, error
                      summary: this.$t('success'),
                      detail: responseapi.data.message,
                      life: 3000, // Toast disappears after 3 seconds
                  });
                        this.isLoadingModel = false;
                        this.reset_user();
                        this.$router.push({name:"clientmaster"});

                    })
                    .catch((error) => {
                        //console.log(error);
                        this.isLoadingModel = false;
                        this.$swal.fire(error.response.data);

                    });
            }
            else
            {

            var data = {
                "client_name": this.product.client_name,
                "description":this.product.description,
                "city": this.product.city,
                "state": this.state_dropdownItem.value,
                "pincode": this.product.pinvalue,
                "location":this.product.location,
                "email_id": this.product.email_id,
                "status": this.status_dropdownItem.value,
                "user_id": this.user_dropdownItem.value,
                "created_at": "",
                "created_by": this.id,
                "mobile_number": this.product.mobile_number,
                //"client_role_group": localStorage.getItem('user_key'),
                "client_type":this.client_type_item.value,
                "client_role_group":this.role_group_search?this.role_group_search.value:''
                
                
            };

            console.log(data);
            this.isLoadingModel = true;
            var promise1 = apis.addclient(data);
            promise1
                .then((responseapi) => {
                    //this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});
                    this.$toast.add({
                      severity: "success", // success, info, warn, error
                      summary: this.$t('success'),
                      detail: responseapi.data.message,
                      life: 3000, // Toast disappears after 3 seconds
                  });
                    this.isLoadingModel = false;
                    this.reset_user();
                    this.$router.push({name:"clientmaster"});

                })
                .catch((error) => {
                    //console.log(error);
                    this.isLoadingModel = false;
                    this.$swal.fire(error.response.data);

                });
            }

        },
        get_client_master(){
            this.loading = true;
            var data = {
                
            };
            var promise = apis.get_client_type_master(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                if (response.data.data) {
                    var temp_data = [];
                    for (let x = 0; x < response.data.data.length; x++) {
                        temp_data.push({
                            name: response.data.data[x].client_type_name, value: response.data.data[x].client_type_name
                        });

                    }
                    this.client_type_items = temp_data;
                }

            });
        },
        get_role_group_list()
    {  var data = {};
      var promise = apis.RoleGroupMasterList(data);
      promise.then((response) => {
        for (let a = 0; a < response.data.data.length; a++) 
        {
          
          this.role_group_dropdown.push({name:response.data.data[a].role_group_name,value:response.data.data[a].role_group_name})
        }
      });
    },
        get_user_list() {
            this.loading = true;
            var data = {
                count: false,
                user_id: localStorage.getItem("id"),
            };
            var promise = apis.userlist(data);
            promise.then((response) => {
                this.loading = false;
                console.log(response);
                if (response.data.data) {
                    var temp_data = [];
                    for (let x = 0; x < response.data.data.length; x++) {
                        temp_data.push({
                            name: response.data.data[x].first_name + response.data.data[x].last_name + ' (' + response.data.data[x].mobile_number
                                + ')', value: response.data.data[x]._id
                        });

                    }
                    this.user_dropdownItems = temp_data;
                }

            });
        },
        
        reset_user() {
            this.product = {};
            this.submitted = false;
            this.status_dropdownItem = null;
            
            this.state_dropdownItem = null;
            this.location_search='';
            this.user_dropdownItem = null;
            this.show_name="Add";
        },
    },
    async created() {
        this.productService = new ProductService();
        this.BreadcrumbService = new BreadcrumbService();
        let Breadcrum_data = this.BreadcrumbService.client_add()
        this.breadcrumbHome=Breadcrum_data.home;
        this.breadcrumbItems=Breadcrum_data.items;

        this.id = localStorage.getItem('id');
        this.get_user_list();
        this.get_client_master();
        this.get_role_group_list();
        if(localStorage.getItem('saveclientdata'))
      {
        this.product=await JSON.parse(localStorage.getItem('saveclientdata'));
        console.log(this.product);
        this.status_dropdownItem={};
        this.status_dropdownItem.value = await this.product.status;
        this.status_dropdownItem.name = await this.product.status;

        this.state_dropdownItem={};
        this.state_dropdownItem.value = await this.product.state;
        this.state_dropdownItem.name = await this.product.state;
        this.product.pinvalue = await this.product.pincode;

        if ( this.product.client_role_group) 
        {   this.role_group_search=null;
            this.role_group_search={name:this.product.client_role_group,value:this.product.client_role_group};
        }

        if (this.product.result) 
        {
            this.user_dropdownItem={};
            this.user_dropdownItem.value = await this.product.user_id;
            this.user_dropdownItem.name  = await this.product.result[0].first_name + this.product.result[0].last_name+' ('+this.product.result[0].mobile_number+')';
        }
       
       
        if(this.product.location)
        {
            this.location_search={};
            this.location_search={ "name": this.product.location?this.product.location.Label:'', "value":this.product.location?this.product.location:''};
        }

        if(this.product.client_type)
        {
            this.client_type_item={};
            this.client_type_item={ "name": this.product.client_type?this.product.client_type:'', "value":this.product.client_type?this.product.client_type:''};
        }
        
        this.place_details=this.product.location?this.product.location:'';
        
        this.show_name="Update"


      }

      await localStorage.setItem('saveclientdata','');

        
        

    },

    components: { downloadExcel: JsonExcel },
}
</script>
